html {
  background-color: #1D3557;
  color: #F1FAEE;
}

.App {
  width:1088px;
  margin:auto;
  text-align: center;
}

.help {
  font-family: 'Press Start 2P', cursive;
  font-size:10px;
  float:left;
  margin-top:10px;
  text-align: left;
}
.help a {
  color: #F1FAEE;
}